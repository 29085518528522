<template>
    <div class="container">
        <div class="title-block">
            <div class="heading-small">
                Contact
            </div>
        </div>

        <div class="content">
            <div class="row gx-5">
                <div class="col-12 col-md-8 mb-4 mb-md-0">
                    <div class="form">
                        <div class="heading-small mb-3">
                            Contact us
                        </div>
                        <div class="paragraph-small">
                            To contact us fill out the form below!
                        </div>

                        <form @submit.prevent="submitForm">
                            <div class="mb-4">
                                <label for="name" class="form-heading">Name</label>
                                <input type="text" v-model="formData.name" name="name" placeholder="Enter your name" required>
                            </div>

                            <div class="mb-4">
                                <label for="email" class="form-heading">Email Address</label>
                                <input type="email" v-model="formData.email" name="email" placeholder="Enter your email" required>
                            </div>

                            <div class="mb-4">
                                <label for="message" class="form-heading">Message</label>
                                <div>
                                    <textarea name="message" v-model="formData.message" cols="30" rows="10" required placeholder="Hi there, I’m reaching out because I think we can collaborate…"></textarea>
                                </div>
                            </div>

                            <div>
                                <button type="submit" class="dark-button" style="width: 100%;">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-12 col-md">
                    <div class="info-block">
                        <div class="info-heading">
                            Our offices
                        </div>
                        <div class="paragraph-small">
                            We will be glad to host you in our office! And also treat coffee!
                        </div>
                    </div>

                    <div class="info-block">
                        <div class="info-heading">
                            Our office
                        </div>
                        <div class="paragraph-small">
                            908 Broadway,<br>
                            San Francisco,<br>
                            CA 94133
                        </div>
                    </div>

                    <div class="info-block">
                        <div class="info-heading">
                            Working hours
                        </div>
                        <div class="paragraph-small">
                            9AM - 3PM, Mon to Fri
                        </div>
                    </div>

                    <div class="info-block">
                        <div class="info-heading">
                            Contact
                        </div>
                        <div class="paragraph-small">
                            01 (509) 688 6812
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <thank-block />
</template>

<script>
import ThankBlock from '@/components/ThankBlock.vue'
export default {
    name: 'ContactView',
    components: {
        ThankBlock        
    },
    data() {
        return {
            formData: {
                name: null,
                email: null,
                message: null
            }
        }
    },
    mounted() {
        this.updateMetaTags();
    },
    methods: {
        submitForm() {
            alert('Thank you! We will contact you as soon as possible!')

            this.formData.name = ''
            this.formData.email = ''
            this.formData.message = ''
        },
        updateMetaTags() {
            document.title = `Contact - ${this.domainName}`;
            this.setMetaTag('description', `${this.domainName} - contact us.`);
            this.setMetaTag('keywords', this.domainName);
            this.setMetaTag('robots', 'index, follow');
        },
        setMetaTag(name, content) {
            let element = document.querySelector(`meta[name="${name}"]`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute('name', name);
                document.head.appendChild(element);
            }
            element.setAttribute('content', content);
        }
    },
    computed: {
        domainName() {
            return this.$domainInfo.name;
        }
    }
}
</script>

<style scoped>
    .content {
        margin: 0 auto;
        margin-top: 50px;
        width: 80%;
        text-align: start;
    }

    .form {
        padding: 40px;
        border: 1px solid #eee;
    }

    .form form {
        margin-top: 20px;
    }

    .form input {
        border: 0;
        padding: 10px 20px;
        border: 1px solid #eee;
        width: 100%;
    }

    .form input:focus {
        border: 1px solid #0f0f0f;
        outline: none;
    }

    .form textarea {
        width: 100%;
        padding: 10px 20px;
        border: 1px solid #eee;
    }

    .form textarea:focus {
        border: 1px solid #0f0f0f;
        outline: none;
    }

    .info-block {
        margin-bottom: 40px;
    }

    .info-heading {
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .form-heading {
        font-weight: 300;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    @media (max-width: 768px) {
        .content {
            width: 100%;
        }
    }
</style>

<template>
    <div class="container">
        <div class="title-block">
            <div class="label-small">
                What we believe in
            </div>
            <div class="heading-small">
                Stay with us and you are aware of all events today and always.
            </div>
        </div>

        <div class="divider"></div>

        <div class="content">
            <div class="row align-items-center">
                <div class="col-12 col-md-4 mb-md-0">
                    <div class="label-small">
                        About
                    </div>
                    <div class="heading-small my-3">
                        Who we are
                    </div>
                    <div class="paragraph-small">
                        {{ domainName }} offers original coverage of the global blockchain and cryptocurrency news, provides opinions, reviews, guides, introduces to Crypto people with an aim to help the general public understand and successfully use these technologies now and in the future.
                    </div>
                    <div class="mt-3">
                        <router-link to="/about">
                            <button class="dark-button">Learn More</button>
                        </router-link>
                    </div>
                </div>
                <div class="col-12 col-md image" style="margin: auto 0;">
                    <div class="d-flex justify-content-center justify-content-md-end">
                        <img src="../assets/about.jpg" alt="about" class="img-fluid">
                    </div>
                </div>
            </div>

            <div class="row align-items-center mt-5">
                <div class="col-12 col-md image" style="margin: auto 0;">
                    <div class="d-flex justify-content-center justify-content-md-start">
                        <img src="../assets/team.png" alt="png" class="img-fluid">
                    </div>
                </div>
                <div class="col-12 col-md-4 mt-4 mb-md-0">
                    <div class="label-small">
                        Team
                    </div>
                    <div class="heading-small my-3">
                        What we do
                    </div>
                    <div class="paragraph-small">
                        Every day our team of editors are looking for the most relevant and important news in the world, our goal is to keep you up to date with all the events!
                    </div>
                    <div class="mt-3">
                        <router-link to="/blog">
                            <button class="dark-button">Our Articles</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <thank-block />
</template>

<script>
import ThankBlock from '@/components/ThankBlock.vue'
export default {
    name: 'HomeView',
    components: {
        ThankBlock
    },
    mounted() {
        this.updateMetaTags()
    },
    methods: {
        updateMetaTags() {
            document.title = `Home - ${this.domainName}`;
            this.setMetaTag('description', `${this.domainName} - Stay with us and you are aware of all events today and always.`);
            this.setMetaTag('keywords', this.domainName);
            this.setMetaTag('robots', 'index, follow');
        },
        setMetaTag(name, content) {
            let element = document.querySelector(`meta[name="${name}"]`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute('name', name);
                document.head.appendChild(element);
            }
            element.setAttribute('content', content);
        }
    },
    computed: {
        domainName() {
            return this.$domainInfo.name;
        }
    }
}
</script>

<style scoped>
    .content {
        margin-top: 50px;
        text-align: start;
    }

    img {
        width: 86%;
    }

    @media (max-width: 768px) {
        .image {
            display: none;
        }
    }
</style>

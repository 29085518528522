<template>
    <div class="cookie desktop px-4 py-2">
        <div class="d-flex">
            <div class="mt-2 me-3">
                Our site uses Cookies. Please allow Cookies Policy.
            </div>
            <button class="dark-button" @click.prevent="closeCookie">
                Allow
            </button>
        </div>
    </div>
    <div class="cookie mobile px-4 py-2">
        <div>
            <div class="mt-2 mb-2">
                Our site uses Cookies. Please allow Cookies Policy.
            </div>
            <button class="dark-button mb-2" style="width: 100%;" @click.prevent="closeCookie">
                Allow
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CookieAllow',
    methods: {
        closeCookie() {
            $cookies.set('isCookie' , true, "24h");
            this.$emit('close');
        },
    }
}
</script>

<style scoped>
    .cookie {
        background: white;
        border: 1px solid black;
        position:fixed;
        top: 86vh;
        left: 30px;
    }

    .mobile {
        display: none;
    }

    @media (max-width: 768px) {
        .desktop {
            display: none;
        }

        .mobile {
            display: block;
        }

        .cookie {
            top: 80vh;
            left: 0;
        }
    }
</style>
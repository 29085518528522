<template>
    <div class="block mx-3 mt-5">
        <div class="block-content">
            <div class="heading-small mb-3">
            Thank you for reading!
            </div>
            <div class="paragraph-large mb-4">
                Our editorial team is working hard for you and is constantly looking for new information that will be of interest to you!
            </div>
            <div>
                <router-link to="/blog">
                    <button class="dark-button-large">More articles</button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .block {
        background: #eee;
        padding: 80px;
    }

    .block-content {
        width: 40%;
        margin: 0 auto;
    }

    @media (max-width: 768px) {
        .block {
            padding: 50px;
        }

        .block-content {
            width: 100%;
        }
    }
</style>
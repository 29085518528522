<template>
    <header class="nav-container">
        <div class="logo">
            <router-link to="/" class="logo-link">
            <img src="../assets/logo.png" alt="logo" height="42px" class="logo-img">
            <span>{{ domainName }}</span>
            </router-link>
        </div>
        <nav class="nav-links">
            <router-link to="/" class="nav-item">Home</router-link>
            <router-link to="/about" class="nav-item">About us</router-link>
            <router-link to="/blog" class="nav-item">Blog</router-link>
            <router-link to="/contact" class="nav-item">Contact</router-link>
        </nav>
        <div class="contact-button">
            <router-link to="/contact">
                <button class="dark-button">Contact us</button>
            </router-link>
        </div>
        <button class="burger" @click="toggleMenu">
            <span>&#9776;</span>
        </button>
    </header>
</template>
  
<script>
export default {
    name: 'Navbar',
    data() {
        return {
            menuOpen: false,
        };
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
            document.querySelector('.nav-links').classList.toggle('active', this.menuOpen);
            document.querySelector('.contact-button').classList.toggle('active', this.menuOpen);
        },
    },
    computed: {
        domainName() {
            return this.$domainInfo.name;
        }
    }
  };
</script>
  
<style scoped>
    .nav-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 40px 60px;
    }
  
    .logo-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: inherit;
    }
  
    .logo-img {
        margin-right: 10px;
    }
  
    .nav-links {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;

        font-weight: 400;
        font-size: 12px;
        color: #0f0f0f;
        text-decoration: none;
        text-transform: uppercase;
    }

    .nav-links a.router-link-exact-active {
        font-weight: 600;
    }
  
    .nav-item {
        margin: 0 1rem;
        text-decoration: none;
        color: inherit;
    }
    
    .contact-button {
        margin-left: auto;
    }
    
    .burger {
        display: none;
        font-size: 2rem;
        background: none;
        border: none;
        cursor: pointer;
    }
  
    @media (max-width: 768px) {
        .nav-container {
            padding: 10px 20px;
        }

        .nav-links {
            display: none;
            flex-direction: column;
            position: absolute;
            top: 100%;
            left: 0;
            background-color: #fff;
            width: 100%;
            text-align: center;
            z-index: 1;
        }

        .nav-links.active {
            display: flex;
        }

        .nav-item {
            margin: 1rem 0;
        }

        .contact-button {
            display: none;
        }

        .burger {
            display: block;
            color: #0f0f0f;
            position: relative;
            left: 20px;
        }
    }
  </style>
  
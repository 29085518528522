<template>
    <cookie-allow v-if="isCookieShow" @close="closeCookie" />

    <navbar />

    <router-view/>

    <footer>
        <div class="d-flex justify-content-center">
            <img src="./assets/logo.png" alt="logo" height="20px" class="me-2">
            2024. Powered by {{ domainName }}.
        </div>
    </footer>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import CookieAllow from '@/components/CookieAllow.vue'

export default {
    name: 'App',
    components: {
        Navbar, CookieAllow
    },
    data() {
        return {
            isCookieShow: true
        }
    },
    mounted() {
        this.checkCookie();
    },  
    methods: {
        closeCookie() {
            this.isCookieShow = false
        },
        checkCookie() {
            if ($cookies.get('isCookie')) {
                this.isCookieShow = false
            }
        }
    },  
    computed: {
        domainName() {
            return this.$domainInfo.name;
        }
    }
}
</script>

<style>
    #app {
        font-family: 'Montserrat', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #0f0f0f;
    }

    .nav div.links a {
        font-weight: 400;
        font-size: 12px;
        color: #0f0f0f;
        text-decoration: none;
        text-transform: uppercase;
    }

    .nav div.links a.router-link-exact-active {
        font-weight: 600;
    }

    div.logo a {
        font-weight: 500;
        text-transform: none;
        text-decoration: none;
        color: #0f0f0f;
    }

    footer {
        font-size: 14px;
        padding: 40px;
    }

    /* Main Styles */

    .divider {
        height: 1px;
        background-color: #eee;
    }

    .heading-small {
        font-weight: 500;
        font-size: 32px;
    }

    .label-small {
        color: #909090;
        text-transform: uppercase;
        font-size: 14px;
    }

    .paragraph-small {
        font-size: 14px;
        line-height: 2em;
    }

    .paragraph-large {
        font-size: 16px;
        line-height: 2em;
        font-weight: 400;
    }

    .title-block {
        width: 70%;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    @media (max-width: 768px) {
        .title-block {
            width: 100%;
        }
    }

    /* Buttons */

    .dark-button-large {
        border: 0;
        background: #0f0f0f;
        color: white;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 500;
        padding: 20px 30px;
    }

    .dark-button-large:hover {
        background: #2c2c2c;
    }

    .dark-button {
        border: 0;
        background: #0f0f0f;
        color: white;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 20px;
    }

    .dark-button:hover {
        background: #2c2c2c;
    }

    .content p img {
        border-radius: 1em;
        width: 100%;
    }
</style>

import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import BlogView from '@/views/BlogView.vue'
import ContactView from '@/views/ContactView.vue'
import ArticleDetailView from '@/views/ArticleDetailView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: { title: 'Home - Title' }
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView,
        meta: { title: 'About Us - Title' }
    },
    {
        path: '/blog',
        name: 'blog',
        component: BlogView,
        meta: { title: 'Blog - Title' }
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactView,
        meta: { title: 'Contact Us - Title' }
    },
    {
        path: '/article/:slug',
        name: 'articleDetail',
        component: ArticleDetailView,
        props: true,
        meta: { title: 'Article - Title' }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

<template>
    <div class="container">
        <div class="title-block">
            <div class="heading-small">
                Blog
            </div>
        </div>
        <div v-if="news && news.length">
            <div v-for="article in news" :key="article.id" class="article-item">
                <router-link :to="{ name: 'articleDetail', params: { slug: article.slug } }">
                    <div class="article">
                        <div class="row">
                            <div class="col-12 col-md-3 mb-4 mb-md-0">
                                <img v-if="article.image_url" :src="article.image_url" alt="Article Image">
                            </div>
                            <div class="col">
                                <div class="align-top">
                                    <h2>{{ article.title }}</h2>
                                    <p v-html="trunContent(article.content)"></p>
                                </div>
                                <div style="margin-top: 30px;">
                                    <div class="d-flex justify-content-between">
                                        <div class="d-flex">
                                            <font-awesome-icon :icon="['fas', 'user']" style="margin-top: 3px;" class="me-2" />
                                            <p>{{ article.author }}</p>
                                        </div>
                                        <div class="d-flex">
                                            <font-awesome-icon :icon="['fas', 'clock']" style="margin-top: 3px;" class="me-2" />
                                            <p>{{ article.publication_date }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
            <nav v-if="news.length > 10">
                <ul class="pagination">
                    <li v-for="pageNumber in totalPages" :key="pageNumber">
                        <a class="page-link" @click.prevent="fetchNewsByPage(pageNumber)" href="#">{{ pageNumber }}</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div v-else>
            <p>Loading...</p>
        </div>
    </div>
</template>

<script>
import axiosInstance from '@/plugins/axios';

export default {
    name: 'BlogView',
    data() {
        return {
            news: [],
            currentPage: 1,
            totalPages: 1,
            site: null
        };
    },
    async mounted() {
        this.site = this.domainSite;
        this.fetchNewsByPage(this.currentPage);
        this.updateMetaTags();
    },
    methods: {
        async fetchNewsByPage(page) {
            try {
                const response = await axiosInstance.post('/get-news?page=' + page, { site: this.site });
                this.news = response.data.data;
                this.totalPages = response.data.last_page;
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        },
        trunContent(content) {
            const maxLength = 180;
            return content.length > maxLength ? content.substring(0, maxLength) + '...' : content;
        },
        updateMetaTags() {
            document.title = `Blog - ${this.domainName}`;
            this.setMetaTag('description', `${this.domainName} - our blog.`);
            this.setMetaTag('keywords', this.domainName);
            this.setMetaTag('robots', 'index, follow');
        },
        setMetaTag(name, content) {
            let element = document.querySelector(`meta[name="${name}"]`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute('name', name);
                document.head.appendChild(element);
            }
            element.setAttribute('content', content);
        }
    },
    computed: {
        domainSite() {
            return this.$domainInfo.site;
        },
        domainName() {
            return this.$domainInfo.name;
        }
    }
}
</script>

<style scoped>
    .article-item {
        margin-bottom: 50px;
    }
    .article-item a {
        color: #0f0f0f;
        text-decoration: none;
    }
    .article {
        text-align: start;
    }
    .article img {
        border-radius: 1em;
        max-width: 100%;
        height: auto;
    }
    .pagination {
        display: flex;
        justify-content: center;
    }
    .pagination .page-item {
        list-style: none;
        margin: 0 5px;
    }
    .pagination .page-link {
        border: 1px solid #0f0f0f;
        padding: 5px 10px;
        color: #0f0f0f;
        text-decoration: none;
        cursor: pointer;
        margin-left: 5px;
        margin-right: 5px;
    }
    .pagination .page-link:hover {
        background-color: #f0f0f0;
    }
    .pagination .page-item.disabled .page-link {
        opacity: 0.5;
        cursor: not-allowed;
    }
</style>

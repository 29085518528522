<template>
    <div class="container">
        <div class="title-block">
            <div class="heading-small">
                About Us
            </div>
        </div>

        <div class="divider"></div>

        <div class="content">
            <div class="paragraph-small mb-4">
                {{ domainName }} offers original coverage of the global blockchain and cryptocurrency news, provides opinions, reviews, guides, introduces to Crypto people with an aim to help the general public understand and successfully use these technologies now and in the future.
            </div>
            <div class="paragraph-small mb-4">
                We believe that the most important thing in the Blockchain revolution is the ability of people to understand and embrace the change. Our journalism standards - impartiality, truthfulness, transparency, and accuracy - will help you navigate this extremely dynamic world.
            </div>
            <div class="paragraph-small mb-4">
                Join us in this journey, as there are millions of things to learn and understand. The change happens by bringing all the voices together.
            </div>
            <div class="paragraph-small">
                {{ domainName }} is backed by Antanas Guoga, ultimate blockchain enthusiast, founder of the International Blockchain Centre in Vilnius, Lithuania, entrepreneur and former Member of the European Parliament.
            </div>
        </div>
    </div>

    <thank-block />
</template>

<script>
import ThankBlock from '@/components/ThankBlock.vue'

export default {
    name: 'AboutView',
    components: {
        ThankBlock
    },
    mounted() {
        this.updateMetaTags()
    },
    methods: {
        updateMetaTags() {
            document.title = `About - ${this.domainName}`;
            this.setMetaTag('description', `${this.domainName} - offers original coverage of the global blockchain and cryptocurrency news, provides opinions, reviews, guides, introduces to Crypto people.`);
            this.setMetaTag('keywords', this.domainName);
            this.setMetaTag('robots', 'index, follow');
        },
        setMetaTag(name, content) {
            let element = document.querySelector(`meta[name="${name}"]`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute('name', name);
                document.head.appendChild(element);
            }
            element.setAttribute('content', content);
        }
    },
    computed: {
        domainName() {
            return this.$domainInfo.name;
        }
    }
}
</script>

<style scoped>
    .content {
        margin: 0 auto;
        margin-top: 50px;
        width: 70%;
    }

    @media (max-width: 768px) {
        .content {
            width: 100%;
        }
    }
</style>
